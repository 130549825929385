<template>
  <div class="content-box">
    <div class="content-box-left">{{ title(desc) }}</div>
    <div class="content-box-right">
      <el-checkbox
        class="platform-check-all"
        :indeterminate="formObj.isIndeterminate"
        v-model="formObj.checkAll"
        @change="CheckAllFn"
        >全选</el-checkbox
      >
      <el-checkbox-group
        class="platform-group"
        v-model="formObj.checkedform"
        @change="selectCheckAllFn"
      >
        <el-checkbox
          class="platform-check-more"
          v-for="item in formObj.checkedData"
          :label="item"
          :key="item.value"
        >
          {{ item.label }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    desc: {
      // 描述是哪一种字典
      type: String,
      default: "",
    },
    zwfw:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 类型
      formObj: {
        checkAll: false, // 类型全选
        checkedform: [], //类型已选
        checkedData: [], //类型 可选数据
        isIndeterminate: false, //类型不确定状态
      },
      // 根据desc匹配标题
      title: (desc) => {
        return {
          platform_type: "平台类型",
          body_type: "主体类型",
          is_authenticate: "认证情况",
        }[desc];
      },
    };
  },
  created() {
    // 根据描述去查字典 || 匹配标题
    this.GetDictionary();
  },
  methods: {
    // 根据描述去查字典
    GetDictionary() {
      this.formObj.checkedData =
        JSON.parse(window.localStorage.getItem(this.desc)) || [];
      if (this.zwfw&&this.desc=='body_type'){
        this.formObj.checkedData =
        JSON.parse(window.localStorage.getItem(this.desc)).filter((item => item.value != 5 && item.value != 62)) || [];
      }
    },
    // 全选按钮
    CheckAllFn(val) {
      this.$main.selectAll(val, this.formObj);
    },
    // 多选按钮
    selectCheckAllFn({ length }) {
      this.$main.selections(length, this.formObj); 
    },
    // 父组件获取数据
    getData: () => this.formObj,
    // 父组件设置数据
    setData(formType) {
      this.$main.backMyInfo(this.formObj,formType + ""); // 回显
    },
    // 重置筛选框
    resetCom() {
      this.formObj = this.$options.data().formObj;
      this.GetDictionary(); // 重置的时候也得去本地里面查字典
    },
  },
};
</script>

<style scoped lang="scss">
.content-box {
  display: flex;
  min-height: 52px;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  border-bottom: 1px solid #e4e6ec;
  .content-box-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 16px;
    width: 96px;
    height: 100%;
  }
  .content-box-right {
    display: flex;
    width: calc(100% - 96px);
    height: 100%;
    .platform-check-all {
      //全选
      margin: 16px 48px 0px 0px;
      ::v-deep .el-checkbox__label {
        color: #333333;
      }
      ::v-deep .el-checkbox__inner {
        border-color: #e4e6ec;
      }
      ::v-deep .el-checkbox__input.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
      ::v-deep .el-checkbox__input.is-indeterminate {
        //半选
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    .platform-group {
      width: calc(100% - 100px);
      height: 100%;
      padding-bottom: 16px;
      .platform-check-more {
        //多选
        margin: 16px 48px 0px 0px;
        ::v-deep .el-checkbox__label {
          color: #333333;
        }
        ::v-deep .el-checkbox__inner {
          border-color: #e4e6ec;
        }
      }
      ::v-deep .el-checkbox.check-more.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    ::v-deep .el-radio {
      margin-top: 18px;
      margin-right: 34px;
      .el-radio__label {
        color: #333333;
      }
      .el-radio__inner {
        border-color: #e4e6ec;
      }
      .el-radio__input.is-checked {
        .el-radio__inner {
          background-color: #f58030;
        }
      }
    }
  }
}
</style>